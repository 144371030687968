import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { LoadingIcon } from "../components/LoadingIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "100%",
    },
  })
);

export const Loading: React.FC = (props: any) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <Box role="img" aria-label="Loading">
        <LoadingIcon size={64} />
      </Box>
    </Box>
  );
};

export default Loading;
